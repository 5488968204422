import React, { useState } from 'react';
import { Col, Container, InputGroup, Row } from 'react-bootstrap';
import JobCard from './JobCard';
import PaginationCommon from '../../../common-component/pagination-common/PaginationCommon';
import Form from 'react-bootstrap/Form';
import './JobCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const JobPanel = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    const jobList = [
        {
            jobTitle: "UI\\UX Designer - Intern",
            postingDate: "Apr 3, 2025",
            experienceRequired: '0.6 to 1',
            "skills": "Figma, Prototyping, Wireframing",
            jobDescription: "",
            education: "",
            location: "Magarpatta, Pune",
            languages: "English",
            category: "Design"
        },
        {
            jobTitle: "Flutter Developer",
            postingDate: "Apr 3, 2025",
            experienceRequired: '1+',
"skills": "API, Database, PLSQL, REST Web Services, and Java. Nice to have: React Native.",
jobDescription: "",
            education: "",
            location: "Magarpatta, Pune",
            languages: "English",
            category: "Design"
        },
        {
            jobTitle: "React Developer",
            postingDate: "Apr 3, 2025",
            experienceRequired: '1+',
            "skills": "Javascript, React-JS, HTML 5, CSS, Github, Bootstrap",
            jobDescription: "",
            education: "",
            location: "Magarpatta, Pune",
            languages: "English",
            category: "Development"
        },
        {
            jobTitle: "Tech System Administrator",
            postingDate: "Jan 8, 2025",
            experienceRequired: '-',
            "skills": "Azure Cloud Computing, MS Windows, Azure CLI, PowerShell, Azure DevOps, Azure ARM, Azure Log Analytics, Azure Monitoring Tools, Azure Components, Dashboard, Operational Support, VM",
            jobDescription: "1. Mandatory Skills – Azure Cloud Computing, MS Windows, Azure CLI, PowerShell, Azure DevOps, Azure ARM, Azure Log Analytics, Azure Monitoring Tools, Azure Components, Dashboard, Operational Support, VM 2. Good To Have - Certifications - AZ-900, AZ-104 ",
            education: "",
            location: "-",
            languages: "English",
            category: "Development"
        },
        {
            jobTitle: "Java Developer",
            postingDate: "Jan 8, 2025",
            experienceRequired: '-',
            "skills": "Java, Core Java, Java 8+, Spring Boot, Microservices, Hibernate,  Database, SQL/MySQL, PostgreSQL",
            jobDescription: "1. Mandatory Skills – Java, Core Java, Java 8+, Spring Boot, Microservices, Hibernate,  Database, SQL/MySQL, PostgreSQL",
            education: "",
            location: "NA",
            languages: "English",
            category: "Development"
        },
        {
            jobTitle: "AWS Engineer",
            postingDate: "Jan 8, 2025",
            experienceRequired: '-',
            skills: "Terraform, Linux, Python/JavaScript, PowerShell/Linux, Azure, Kubernetes, DevOps, GCP, Private Cloud Foundry, VMware Tanzu Application Service, OpenStack",
            jobDescription: "1. Mandatory Skills – Terraform, Linux, Python/JavaScript, PowerShell, Linux, Azure, Kubernetes, DevOps, GCP, Private Cloud Foundry, VMware Tanzu Application Service, OpenStack 2. Good To Have - Certifications in any of GCP, AWS or Azure ",
            education: "",
            location: "Pune",
            languages: "English",
            category: "Development"
        },{
            jobTitle: "Python Automation",
            postingDate: "Jan 8, 2025",
            experienceRequired: '-',
            skills: "ServiceNow, Splunk, Appdynamic, Bactch Processing Tool",
            jobDescription: "1. Mandatory Skills – ServiceNow, Splunk, Appdynamic, Bactch Processing Tool",
            education: "",
            location: "Pune",
            languages: "English",
            category: "Development"
        },

        {
            jobTitle: "Automation Developer",
            postingDate: "Jan 8, 2025",
            experienceRequired: '-',
            skills: "Python, Ansible, Big Panda, Amelia, ServiceNow, Splunk, AppDynamics, Robust",
            jobDescription: "1. Mandatory Skills – ServiceNow, Splunk, Appdynamic, Bactch Processing Tool",
            education: "",
            location: "Pune",
            languages: "English",
            category: "Development"
        }

        ,{
            jobTitle: "Big Data Architect",
            postingDate: "Dec 8, 2024",
            experienceRequired: 8,
            skills: "Spark, Scala/Pyspark, Hadoop ecosystem including Hive, Sqoop, Impala, Oozie, Hue, Java/ Python, SQL, Flume, bash",
            jobDescription: "1. Mandatory Skills – Spark, Scala/Pyspark, Hadoop ecosystem including Hive, Sqoop, Impala, Oozie, Hue, Java/ Python, SQL, Flume, bash (shell scripting) 2. Secondary Skills – Apache Kafka, Storm, Distributed systems, good understanding of networking, security (platform & data) concepts, Kerberos, Kubernetes 3. Excellent understanding of distributed system architectures, in-depth knowledge on architecture and implementation of Hadoop Stack, Spark, Data warehousing concepts 4. Understanding of Data Governance concepts and experience implementing metadata capture, lineage capture, business glossary 5. Experience implementing CICD pipelines and working experience with tools like SCM tools such as GIT, Bit bucket, etc 6. Hands-on experience in writing data ingestion pipelines, data processing pipelines using spark and sql, experience in implementing SCD type 1 & 2, auditing, exception handling mechanism 7. Data Warehousing projects implementation with either Java, or Scala based Hadoop programming background. 8. Proficient with various development methodologies like waterfall, agile/scrum. 9. Exceptional communication, organization, and time management skills 10. Collaborative approach to decision-making & Strong analytical skills 11. Good To Have - Certifications in any of GCP, AWS or Azure, Cloudera 12. Work on multiple Projects simultaneously, prioritizing appropriately 13. Knowledge on pre-sales activities such as RFP response, estimation techniques, planning concepts is an added advantage",
            education: "",
            location: "Pune",
            languages: "English",
            category: "Development"
        },

        {
            jobTitle: "Data Engineer",
            postingDate: "Dec 8, 2024",
            experienceRequired: 5,
            skills: "Data Engineering, Data Governance, Data Warehousing, Data Integration, Python, PySpark, AWS, Snowflake, AWS Glue, Lambda, DynamoDB, S3, Kinesis, RDBMS, NoSQL, Power BI",
            jobDescription: "1. Design and develop data pipelines that extract data from various sources, transform it into the desired format, and load it into data storage systems. 2. Integrate data from multiple sources such as databases, data warehouses, APIs, and external systems. 3. Ensure data consistency and integrity, performing data validation and cleaning as needed. 4. Transform raw data into usable formats through data cleansing, aggregation, and enrichment techniques. 5. Optimize data pipelines for performance, scalability, and efficiency. 6. Monitor and tune data systems, resolving performance bottlenecks and implementing strategies like caching and indexing. 7. Implement data quality checks within data pipelines for accuracy and completeness. 8. Establish data governance for algorithms used in analysis and automated decision-making. 9. Prepare/maintain documentation around best practices covering data architecture, security, performance, and scalability. 10. Collaborate with business and technical teams, demonstrating strong analytical and communication skills. 11. Additional skills: Experience with Power BI Analytical tool is a plus.",
            education: "Bachelor’s degree in Computer Science, Data Science, Software Engineering, or related field. Master’s degree preferred.",
            location: "Pune",
            languages: "English",
            category: "Development"
        },
        {
            jobTitle: "Senior Hadoop Administrator / Architect (Systems Administrator / Sys Admin)",
            postingDate: "Dec 8, 2024",
            experienceRequired: 8,
            skills: "Hadoop Administration, Linux Systems Administration, Ambari, Nagios, Cloudera Manager, HDFS, YARN, Ozone, Spark, Kudu, Hive, Shell scripting, Python, Data Governance, Security, Backup Procedures",
            jobDescription: "1. Install, configure, and maintain Hadoop clusters (Cloudera/HortonWorks). 2. Monitor cluster performance and ensure high availability. 3. Manage Hadoop ecosystem components including HDFS, YARN, Ozone, Spark, Kudu, and Hive. 4. Perform routine cluster maintenance and troubleshoot issues. 5. Implement and manage security and data governance across the Hadoop ecosystem. 6. Monitor systems health, identify performance bottlenecks, and optimize cluster performance. 7. Collaborate with cross-functional teams to support big data applications. 8. Perform Linux system administration tasks, manage system configurations, and ensure data integrity and backup procedures. 9. Proficiency in Hadoop administration tools like Ambari, Nagios, and Cloudera Manager. 10. Strong problem-solving skills, with the ability to handle production issues effectively. 11. Excellent communication and collaboration skills with technical teams.",
            education: "Bachelor’s degree in Computer Science, Information Technology, or a related field.",
            location: "Mumbai, India",
            languages: "English",
            category: "IT Infrastructure and Operations"
        },
        {
            jobTitle: "AWS QuickSight Developer",
            postingDate: "Dec 8, 2024",
            experienceRequired: 3,
            skills: "AWS Glue, Athena, QuickSight, Data Pipelines, SQL, Data Warehousing, ETL, Snowflake, AWS S3, E-commerce Analytics, Data Governance, Data Security, Dashboard Development, Query Optimization",
            jobDescription: "1. Design, develop, and maintain data pipelines using AWS Glue for efficient data ingestion, transformation, and storage. 2. Create and optimize queries using Amazon Athena to enable fast and cost-effective analysis of large datasets. 3. Develop interactive and visually appealing dashboards and reports using Amazon QuickSight to provide actionable insights to stakeholders. 4. Collaborate with cross-functional teams including data engineers, data scientists, and business analysts to understand requirements and deliver scalable solutions. 5. Ensure data quality and integrity throughout the data lifecycle, implementing best practices for data governance and security. 6. Stay updated with the latest AWS services, features, and best practices, and continuously seek opportunities to improve existing processes and systems. 7. Proven experience integrating AWS services with Snowflake, AWS S3, and other data sources. 8. Strong proficiency in SQL and query optimization techniques. 9. Experience with data modeling, ETL processes, and data warehousing concepts. 10. Familiarity with e-commerce platforms and understanding of e-commerce data domains such as sales, inventory, and customer behavior. 11. AWS certifications (e.g., AWS Certified Developer, AWS Certified Data Analytics) are a plus.",
            education: "Bachelor’s degree in Computer Science, Engineering, or related field.",
            location: "Pune",
            languages: "English",
            category: "Development"
        },
        {
            jobTitle: "AWS IoT Sr. Developer / Architect",
            postingDate: "Dec 8, 2024",
            experienceRequired: 8,
            skills: "AWS Lambda, GraphQL, NoSQL, IoT, Kinesis, SNS, SQS, NodeJS, Java, C++, Microservices, Serverless frameworks, DevOps, CI/CD, AWS SDK",
            jobDescription: "1. Design, develop IoT/Cloud-based JavaScript/NodeJS applications using Amazon Cloud Computing Services. 2. Work closely with cross-functional teams to build high-quality digital applications for the safety industry. 3. Complete understanding of the software development life cycle, from customer needs to product release, with hands-on experience in CI/CD and DevOps. 4. Expertise in developing and maintaining reusable software services for promoting component/library reuse. 5. Strong knowledge of AWS Cloud services such as AWS Lambda, GraphQL, NoSQL databases, AWS Kinesis, SNS, SQS, and IoT applications/integration with IoT devices. 6. Experience in Microservices architecture and proficiency in programming languages like Java, C++, and Go. 7. Hands-on experience with serverless frameworks and AWS services like Lambda, SNS, SES, SQS, SSM, S3, EC2, IAM, CloudWatch, Kinesis, and CloudFormation. 8. Good understanding of IoT protocols like Sparkplug-B is an advantage. 9. Experience working in an Agile development lifecycle and using tools such as Jira, Git, GitLab, SVN, and Bitbucket.",
            education: "Bachelor's degree in Computer Science, Engineering, or related field.",
            location: "Pune",
            languages: "English",
            category: "Development"
        },
        {
            jobTitle: "Scrum Master",
            postingDate: "Dec 8, 2024",
            experienceRequired: 6,
            skills: "Scrum, Agile, JIRA, Confluence, DevOps, CI/CD, SAFe, Kanban, Team Leadership, Conflict Resolution, Stakeholder Management",
            jobDescription: "1. Serve as a servant leader and coach for Agile teams, fostering a culture of continuous improvement. 2. Facilitate Scrum ceremonies such as sprint planning, daily stand-ups, sprint reviews, and retrospectives. 3. Act as a bridge between technical teams, stakeholders, and product owners to ensure alignment on project goals. 4. Track and report key Agile metrics such as team velocity and sprint burndown. 5. Identify risks, conflicts, and dependencies, helping teams to stay on track. 6. Encourage self-organization and accountability within the team. 7. Promote transparency and effective communication through tools like Jira and Confluence. 8. Provide guidance on Agile principles, frameworks, and methodologies. 9. Experience with Agile tools like JIRA, Azure DevOps, or Rally is required. 10. Certifications like PSM or CSM, and familiarity with SAFe, Kanban, or LeSS is a plus.",
            education: "Bachelor's degree in Computer Science, Engineering, or related field.",
            location: "Pune",
            languages: "English",
            category: "Project Management"
        }
    ];

    // Handle search term change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setCurrentPage(1); // Reset to page 1 whenever the search term changes
    };

    // Filter the job list based on the search term
    const filteredJobs = jobList.filter((job) => {
        return (
            job.jobTitle.toLowerCase().includes(searchTerm) ||
            job.skills.toLowerCase().includes(searchTerm)
        );
    });

    // Calculate the total number of pages based on the filtered job list
    const totalPages = Math.ceil(filteredJobs.length / itemsPerPage);

    // Slice the filtered jobs based on the current page
    const currentJobs = filteredJobs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Handle page change
    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return; // Ensure the page is within valid range
        setCurrentPage(page);
    };

    return (
        <section className='job-panel'>
            <Container>
                <Row className='justify-content-center'>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className='text-center mb-4'>
                            <InputGroup className="mb-5">
                                <Form.Control
                                    type="text"
                                    placeholder="Search Job...."
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                 <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {currentJobs.map((job, index) => (
                        <Col key={index} xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div className='mb-4'>
                                <JobCard
                                    jobTitle={job.jobTitle}
                                    postingDate={job.postingDate}
                                    jobDescription={job.jobDescription}
                                    location={job.location}
                                    languages={job.languages}
                                    experienceRequired={job.experienceRequired}
                                    skills={job.skills}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
                <PaginationCommon
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </Container>
        </section>
    );
};

export default JobPanel;
