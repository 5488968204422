// import React, { useState } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
// import "./../../job-seeker/job-card/job-card-modal/CommonModal.css";

// import axios from "axios";
// const GetProfileModal = ({ show, onHide, data }) => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [organization, setOrganization] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");


  
//   const handleSubmit = async (event) => {
//     event.preventDefault();
  
//     // Simple validation to ensure fields are filled
//     if (!name || !email || !organization) {
//       setErrorMessage("Please fill in all fields.");
//       return;
//     }
  
//     setLoading(true);
//     setErrorMessage("");
  
//     try {
//       // Replace with your backend API endpoint to handle the data
//       const response = await axios.post("http://127.0.0.1:3004/api/sendProfileRequest", {
//         name,
//         email,
//         organization,
//         candidateDetails: data, // Attach the candidate's details
//       });
  
//       if (response.status === 200) {
//         // Handle success (e.g., close the modal and show a success message)
//         onHide();
//         alert("Profile request sent successfully!");
//       }
//     } catch (error) {
//       setErrorMessage("Something went wrong, please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   return (
//     <section>
//       <Modal
//         show={show}
//         onHide={onHide}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//         scrollable
//         className="common-main-modal"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">
//             <h4 className="modal-headdd">Request Candidate Profile</h4>
//             <p className="blk-grey-text text-black">
//               We will get back to you shortly with the candidate's profile
//               details. Thank you for your interest!
//             </p>{" "}
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <h5 className="modal-head">Your Name</h5>
//           <Form.Control
//             type="text"
//             placeholder="Enter your name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//           />

//           <h5 className="modal-head" style={{ marginTop: "5px" }}>
//             {" "}
//             Your Email
//           </h5>
//           <Form.Control
//             type="email"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />

//           <h5 className="modal-head" style={{ marginTop: "5px" }}>
//             Organization Name
//           </h5>
//           <Form.Control
//             type="text"
//             placeholder="Enter your organization name"
//             value={organization}
//             onChange={(e) => setOrganization(e.target.value)}
//             required
//           />

//           {errorMessage && <p className="text-danger">{errorMessage}</p>}

//           {/* <h5 className="modal-head">Candidate's Job Title</h5>
//           <p className="blk-grey-text">{data.jobTitle}</p>

//           <h5 className="modal-head">Current Location</h5>
//           <p className="blk-grey-text">{data.location}</p>

//           <h5 className="modal-head">Experience</h5>
//           <p className="blk-grey-text">{data.experience} Years</p>

//           <h5 className="modal-head">Skills</h5>
//           <ul>
//             {data.skills.map((skill, index) => (
//               <li key={index} className="blk-grey-text">
//                 {skill}
//               </li>
//             ))}
//           </ul>

//           <h5 className="modal-head">Certifications</h5>
//           <ul>
//             {data.certifications && data.certifications.length > 0 ? (
//               data.certifications.map((cert, index) => (
//                 <li key={index} className="blk-grey-text">
//                   {cert}
//                 </li>
//               ))
//             ) : (
//               <p>No certifications available</p>
//             )}
//           </ul> */}
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="job-card-main">
//             <button
//               className="know-more-btn-job"
//               type="submit"
//               onClick={handleSubmit}
//             >
//               {" "}
//               {loading ? "Sending..." : "Send Request"}
//             </button>

//             <button className="apply-btn" onClick={onHide} disabled={loading}>
//               Close{" "}
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//     </section>
//   );
// };

// export default GetProfileModal;


import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./../../job-seeker/job-card/job-card-modal/CommonModal.css";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';  


const GetProfileModal = ({ show, onHide, data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name || !email || !organization) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      // Replace with your backend API endpoint to handle the data
      const response = await axios.post("http://127.0.0.1:3004/sendProfileRequest", {
        name,
        email,
        organization,
        candidateDetails: data,
      });

      if (response.status === 200) {
        onHide();
        toast.success('Profile request sent successfully!');
      }
    } catch (error) {
        toast.error('There was an error sending your message. Please try again !');

      setErrorMessage("Something went wrong, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section>
    <Toaster></Toaster>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-main-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="modal-headdd">Request Candidate Profile</h4>
            <p className="blk-grey-text text-black">
              We will get back to you shortly with the candidate's profile
              details. Thank you for your interest!
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="modal-head">Your Name</h5>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <h5 className="modal-head" style={{ marginTop: "5px" }}>
            Your Email
          </h5>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <h5 className="modal-head" style={{ marginTop: "5px" }}>
            Organization Name
          </h5>
          <Form.Control
            type="text"
            placeholder="Enter your organization name"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            required
          />

          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <div className="job-card-main">
            <button
              className="know-more-btn-job"
              type="submit"
              onClick={handleSubmit}
            >
              {loading ? "Sending..." : "Send Request"}
            </button>

            <button className="apply-btn" onClick={onHide} disabled={loading}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default GetProfileModal;
