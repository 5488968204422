import React from 'react'
import BuisnessNeeds from '../../homepage/buisness-needs/BuisnessNeeds'
import WhoWeAre from './WhoWeAre/WhoWeAre'
import { ServicesCarousel } from './services-carousel/ServicesCarousel'
import { Col, Container, Row } from 'react-bootstrap'
import { CommonBanner } from '../../common-component/common-banner/CommonBanner'
const Services = () => {

  const caseStudies = [
    {
      title: "Highly professional staff",
      description:
        "Our mobile and web development team delivers user-friendly apps and scalable platforms, combining cutting-edge tech with seamless functionality to meet diverse business needs.",
      bg: "/assets/images/servicePage/carousel/servicesCarousel.jpeg",
    },
    // {
    //   title: "professional staff",
    //   description:
    //     "Our mobile and web development team delivers user-friendly apps and scalable platforms, combining cutting-edge tech with seamless functionality to meet diverse business needs.",
    //   bg: "/assets/images/servicePage/carousel/servicesCarousel.jpeg",
    // },
    // {
    //   title: "professional staff",
    //   description:
    //     "Our mobile and web development team delivers user-friendly apps and scalable platforms, combining cutting-edge tech with seamless functionality to meet diverse business needs.",
    //   bg: "/assets/images/servicePage/carousel/servicesCarousel.jpeg",
    // }
    
  ];

  return (
    <>
      <section>
        <CommonBanner pageTitle='Services' bg='/assets/images/service-banner-bg.png' />
        <BuisnessNeeds />

        <Row className='me-0 ms-0'>
          <Col lg={6} md={12} xs={12}  className="mb-4">
            <ServicesCarousel CaseStudies={caseStudies} />
          </Col>
          <Col  lg={6} md={12} xs={12}  className="mb-4 mt-2">
            <WhoWeAre />
          </Col>
        </Row>

      </section>

    </>
  )
}

export default Services