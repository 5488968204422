// import React, { useEffect, useState } from 'react';
// import { Col, Container, Form, Row } from 'react-bootstrap';
// import Select from 'react-select';
// import './ContactForm.css'
// import ContactAddress from '../contact-address/ContactAddress';
// // import AOS from 'aos';
// // import 'aos/dist/aos.css';
// import GetQuoteLinearButton from '../../common-component/get-quote-linear-btn/GetQuoteLinearButton';

// const options = [
//   { value: 'Optimus Prime', label: 'Optimus Prime' },
//   { value: 'R-Recruit', label: 'R-Recruit' },
//   { value: 'R-Fillings', label: 'R-Fillings' },
//   { value: 'R-Shopizer', label: 'R-Shopizer' },

//   { value: 'Cloud Solution', label: 'Cloud Solution' },
//   { value: 'Data Migration', label: 'Data Migration' },
//   { value: 'Talent Staffing', label: 'Talent Staffing' },
//   { value: 'Software Modernization (AI/ML)', label: 'Software Modernization (AI/ML)' },
//   { value: 'Web & Mobile App Development', label: 'Web & Mobile App Development' },
//   { value: 'DevOps Automation', label: 'DevOps Automation' },
//   { value: 'Collaborative Product Development', label: 'Collaborative Product Development' },
//   { value: 'ODC Establishment', label: 'ODC Establishment' },
// ];

// const ContactForm = () => {
//   const [selectedOption, setSelectedOption] = useState(null);

//   return (
//     <section className="contact-form"
//     >
//       <Container>
//         <Row className="justify-content-center">
//           <Col xxl={3} xl={4} lg={4} md={5} sm={6} xs={12}>
//             <p className='contact-heading'>Ready to take your
//               <span className='pink-word'> business</span> to new heights?</p>
//           </Col>
//           <Col xxl={5} xl={5} lg={5} md={7} sm={6} xs={12}>
//             <p className="normal-text contact-form-description">
//               We're here to help! Whether you're looking for innovative IT solutions, expert guidance, or simply want to learn more about our services, our team is ready to assist you.
//               <br />
//               Reach out to us through our contact form.
//             </p>
//           </Col>
//         </Row>
//         <Row className="justify-content-center">
//           <Col xxl={3} xl={4} lg={4} md={5} sm={6} xs={12}>
//             <div>
//               <ContactAddress />
//             </div>
//           </Col>
//           <Col xxl={5} xl={5} lg={6} md={7} sm={6} xs={12}>
//             <div className='contact-form-sub mt-4'  >
//               <Form>
//                 <Row>
//                   <Col lg={6} md={12} sm={12} xs={12}>
//                     <Form.Group className="mb-3" controlId="formName">
//                       <Form.Label>Name <span className='red-star'>*</span></Form.Label>
//                       <Form.Control type="text" placeholder="Your Name" />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6} md={12} sm={12} xs={12}>
//                     <Form.Group className="mb-3" controlId="formEmail">
//                       <Form.Label>Email Address <span className='red-star'>*</span></Form.Label>
//                       <Form.Control type="email" placeholder="name@example.com" />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6} md={12} sm={12} xs={12}>
//                     <Form.Group className="mb-3" controlId="formPhone">
//                       <Form.Label>Phone (Optional)</Form.Label>
//                       <Form.Control type="tel" placeholder="Your Phone Number" />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={6} md={12} sm={12} xs={12}>
//                     <Form.Group className="mb-3" controlId="formService">
//                       <Form.Label>Select Product/Service</Form.Label>
//                       <Select
//                         defaultValue={selectedOption}
//                         onChange={setSelectedOption}
//                         options={options}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col lg={12} md={12} sm={12} xs={12}>
//                     <Form.Group className="mb-3" controlId="formMessage">
//                       <Form.Label>Message</Form.Label>
//                       <Form.Control as="textarea" rows={3} placeholder="Write your message here..." style={{ resize: 'none' }}/>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//               </Form>
//               <GetQuoteLinearButton label="Send message" />
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </section >
//   );
// };

// export default ContactForm;
import React, { useState } from "react";
import { Col, Container, Form, Row,Button } from "react-bootstrap";
import Select from "react-select";
import "./ContactForm.css";
import ContactAddress from "../contact-address/ContactAddress";
import GetQuoteLinearButton from "../../common-component/get-quote-linear-btn/GetQuoteLinearButton";
import axios from "axios"; // Axios for sending data to the backend
import toast, { Toaster } from "react-hot-toast";

// Options for the Select dropdown
const options = [
  { value: "Optimus Prime", label: "Optimus Prime" },
  { value: "R-Recruit", label: "R-Recruit" },
  { value: "R-Fillings", label: "R-Fillings" },
  { value: "R-Shopizer", label: "R-Shopizer" },
  { value: "Cloud Solution", label: "Cloud Solution" },
  { value: "Data Migration", label: "Data Migration" },
  { value: "Talent Staffing", label: "Talent Staffing" },
  {
    value: "Software Modernization (AI/ML)",
    label: "Software Modernization (AI/ML)",
  },
  {
    value: "Web & Mobile App Development",
    label: "Web & Mobile App Development",
  },
  { value: "DevOps Automation", label: "DevOps Automation" },
  {
    value: "Collaborative Product Development",
    label: "Collaborative Product Development",
  },
  { value: "ODC Establishment", label: "ODC Establishment" },
];

const ContactForm = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });

  const [selectedOption, setSelectedOption] = useState(null);

  // Handle change for form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle change for the select dropdown
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      service: selectedOption ? selectedOption.value : "",
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form behavior
    try {
      // Send form data to the backend (Express API)
      const response = await axios.post(
        "http://127.0.0.1:3004/send-contact",
        formData
      );
      // console.log('Response:', response);
      // Optionally reset form fields after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
      });
      toast.success("Your message has been sent successfully!");
      // alert('');
    } catch (error) {
      // console.error('Error submitting form:', error);
      toast.error(
        "We encountered an error while sending your message. Please try again or contact us at info@redberyltech.com."
    );
    

      // alert('There was an error sending your message. Please try again.');
    }
  };

  return (
    <section className="contact-form">
      <Container>
        <Toaster></Toaster>
        <Row className="justify-content-center">
          <Col xxl={3} xl={4} lg={4} md={5} sm={6} xs={12}>
            <p className="contact-heading">
              Ready to take your <span className="pink-word">business</span> to
              new heights?
            </p>
          </Col>
          <Col xxl={5} xl={5} lg={5} md={7} sm={6} xs={12}>
            <p className="normal-text contact-form-description">
              We're here to help! Whether you're looking for innovative IT
              solutions, expert guidance, or simply want to learn more about our
              services, our team is ready to assist you.
              <br />
              Reach out to us through our contact form.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xxl={3} xl={4} lg={4} md={5} sm={6} xs={12}>
            <div>
              <ContactAddress />
            </div>
          </Col>
          <Col xxl={5} xl={5} lg={6} md={7} sm={6} xs={12}>
            <div className="contact-form-sub mt-4">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>
                        Name <span className="red-star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>
                        Email Address <span className="red-star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="name@example.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formPhone">
                      <Form.Label>Phone (Optional)</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        placeholder="Your Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formService">
                      <Form.Label>Select Product/Service</Form.Label>
                      <Select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={options}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formMessage">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Write your message here..."
                        style={{ resize: "none" }}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* Ensure button is type "submit" */}

                <div className="main-get-quote">
                  <Button type="submit" className="get-quote-linaer-btnn">
                    Send Message
                  </Button>
                </div>
                {/* <GetQuoteLinearButton label="Send message" type="submit" /> */}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactForm;
