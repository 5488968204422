// import { Form, Modal } from 'react-bootstrap';

// const ApplyModal = (props) => {
//     const { generic = false } = props;
//     console.log(generic)
//     return (
//         <>
//             <Modal
//                 {...props}
//                 size="md"
//                 aria-labelledby="apply-modal-title"
//                 centered
//                 scrollable
//                 className="common-main-modal"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title id="apply-modal-title">
//                         <h4 className="modal-headdd">{generic ? 'Submit Profile' : 'Apply'}</h4>
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form>
//                         <Form.Group className="mb-3" controlId="fullName">
//                             <Form.Label>Full Name *</Form.Label>
//                             <Form.Control type="text" required />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="contact">
//                             <Form.Label>Contact *</Form.Label>
//                             <Form.Control type="tel" required />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="altContact">
//                             <Form.Label>Alternative Contact</Form.Label>
//                             <Form.Control type="tel" />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="email">
//                             <Form.Label>Email *</Form.Label>
//                             <Form.Control type="email" required />
//                         </Form.Group>
//                         {generic ? (
//                             <Form.Group className="mb-3" controlId="category">
//                                 <Form.Label>Job Category *</Form.Label>
//                                 <Form.Control as="select" required>

//                                     <option>Java Developer</option>
//                                     <option>Python Developer</option>
//                                     <option>AWS Engineer</option>
//                                     <option>Cloud Engineer</option>
//                                     <option>Frontend Developer</option>
//                                     <option>Database Admin</option>
//                                     <option>AI Developer</option>
//                                     <option>Bussiness Analyst</option>
//                                     <option>HR Admin</option>
//                                     <option>QA Engineer</option>

//                                 </Form.Control>
//                             </Form.Group>
//                         ) : (
//                             <Form.Group className="mb-3" controlId="position">
//                                 <Form.Label>Position *</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={props.jobTitle} 
//                                     disabled
//                                 />
//                             </Form.Group>
//                         )}
//                         <Form.Group className="mb-3" controlId="resume">
//                             <Form.Label>Resume *</Form.Label>
//                             <Form.Control type="file" accept=".pdf,.doc,.docx" required />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <div className="job-card-main">
//                         <button
//                             className="know-more-btn-job"
//                             onClick={props.onHide}
//                             aria-label={generic ? 'Submit Resume' : 'Submit Application'}

//                         >
//                             {generic ? 'Submit Resume' : 'Submit Application'}
//                         </button>
//                         <button
//                             className="apply-btn"
//                             onClick={props.onHide}
//                             aria-label="Cancel Application"
//                         >
//                             Cancel
//                         </button>
//                     </div>

//                     <button> Send Email</button>
    
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// };

// export default ApplyModal;


// import React, { useState, useRef } from 'react';
// import { Form, Modal } from 'react-bootstrap';
// import emailjs from 'emailjs-com';

// const ApplyModal = (props) => {
//     const { generic = false } = props;
//     const formRef = useRef(); // Reference to the form element

//     // const [formData, setFormData] = useState({
//     //     fullName: '',
//     //     contact: '',
//     //     altContact: '',
//     //     email: '',
//     //     category: '',
//     //     position: '',
//     //     resume: null
//     // });

//     // const handleChange = (e) => {
//     //     const { name, value } = e.target;
//     //     setFormData({
//     //         ...formData,
//     //         [name]: value
//     //     });
//     // };

//     // const handleFileChange = (e) => {
//     //     setFormData({
//     //         ...formData,
//     //         resume: e.target.files[0]
//     //     });
//     // };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         // const { fullName, contact, altContact, email, category, position, resume } = formData;

//         // // Attach file to FormData
//         // const formDataToSend = new FormData();
//         // formDataToSend.append('fullName', fullName);
//         // formDataToSend.append('contact', contact);
//         // formDataToSend.append('altContact', altContact);
//         // formDataToSend.append('email', email);
//         // formDataToSend.append('category', category);
//         // formDataToSend.append('position', position);
//         // formDataToSend.append('resume', resume);

//         // Send the email using emailjs
//         emailjs
//             .sendForm('service_x9z0rh7', 'template_qnmaori', formRef.current, 'njMTLU1ItrCiSbNXj') // Pass the formRef here
//             .then((response) => {
//                 console.log(formRef)
//                 console.log('SUCCESS!', response.status, response.text);
//                 // Close the modal after submission
//                 props.onHide();
//             })
//             .catch((err) => {
//                 console.log(formRef.current)

//                 console.error('FAILED...', err);
//             });
//     };

//     return (
//         <>
//             <Modal
//                 {...props}
//                 size="md"
//                 aria-labelledby="apply-modal-title"
//                 centered
//                 scrollable
//                 className="common-main-modal"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title id="apply-modal-title">
//                         <h4 className="modal-headdd">{generic ? 'Submit Profile' : 'Apply'}</h4>
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form ref={formRef} onSubmit={handleSubmit}> {/* Use formRef */}
//                         <Form.Group className="mb-3" controlId="fullName">
//                             <Form.Label>Full Name *</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 required
//                                 name="fullName"
//                                 // value={formData.fullName}
//                                 // onChange={handleChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="contact">
//                             <Form.Label>Contact *</Form.Label>
//                             <Form.Control
//                                 type="tel"
//                                 required
//                                 name="contact"
//                                 // value={formData.contact}
//                                 // onChange={handleChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="altContact">
//                             <Form.Label>Alternative Contact</Form.Label>
//                             <Form.Control
//                                 type="tel"
//                                 name="altContact"
//                                 // value={formData.altContact}
//                                 // onChange={handleChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="email">
//                             <Form.Label>Email *</Form.Label>
//                             <Form.Control
//                                 type="email"
//                                 required
//                                 name="email"
//                                 // value={formData.email}
//                                 // onChange={handleChange}
//                             />
//                         </Form.Group>
//                         {generic ? (
//                             <Form.Group className="mb-3" controlId="category">
//                                 <Form.Label>Job Category *</Form.Label>
//                                 <Form.Control
//                                     as="select"
//                                     name="category"
//                                     required
//                                     // value={formData.category}
//                                     // onChange={handleChange}
//                                 >
//                                     <option>Java Developer</option>
//                                     <option>Python Developer</option>
//                                     <option>AWS Engineer</option>
//                                     <option>Cloud Engineer</option>
//                                     <option>Frontend Developer</option>
//                                     <option>Database Admin</option>
//                                     <option>AI Developer</option>
//                                     <option>Bussiness Analyst</option>
//                                     <option>HR Admin</option>
//                                     <option>QA Engineer</option>
//                                 </Form.Control>
//                             </Form.Group>
//                         ) : (
//                             <Form.Group className="mb-3" controlId="position">
//                                 <Form.Label>Position *</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={props.jobTitle}
//                                     disabled
//                                     name="position"
//                                 />
//                             </Form.Group>
//                         )}
//                         <Form.Group className="mb-3" controlId="resume">
//                             <Form.Label>Resume *</Form.Label>
//                             <Form.Control
//                                 type="file"
//                                 accept=".pdf,.doc,.docx"
//                                 required
//                                 name="resume"
//                                 // onChange={handleFileChange}
//                             />
//                         </Form.Group>
//                         <Modal.Footer>
//                             <div className="job-card-main">
//                                 <button
//                                     className="know-more-btn-job"
//                                     type="submit"
//                                     aria-label={generic ? 'Submit Resume' : 'Submit Application'}
//                                 >
//                                     {generic ? 'Submit Resume' : 'Submit Application'}
//                                 </button>
//                                 <button
//                                     className="apply-btn"
//                                     onClick={props.onHide}
//                                     aria-label="Cancel Application"
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal.Body>
//             </Modal>
//         </>
//     );
// };

// export default ApplyModal;


// 

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import React, { useState, useRef } from 'react';
// import { Modal, Form } from 'react-bootstrap';
// import axios from 'axios';


// const backendUrl = 'http://127.0.0.1:3004/send-email';

// const ApplyModal = (props) => {
//     const formRef = useRef(null);
//     const [formData, setFormData] = useState({
//         fullName: '',
//         contact: '',
//         email: '',
//         resume: null,
//         role: '',
//     });
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [success, setSuccess] = useState(false);

//     // Handle form input changes
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value,
//         }));
//     };

//     // Handle resume file change
//     const handleFileChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setFormData((prevData) => ({
//                     ...prevData,
//                     resume: reader.result,
//                 }));
//             };
//             reader.readAsDataURL(file); // Convert file to base64
//         }
//     };

//     // Handle form submission
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setError(null);
//         setSuccess(false);

//         try {
//             const response = await axios.post(backendUrl, {
//                 ...formData,
//                 resume: formData.resume, 
//                 role: props.jobTitle || formData.role,         });

//             setSuccess(true);
//             setFormData({
//                 fullName: '',
//                 contact: '',
//                 email: '',
//                 resume: null,
//                 role: '',
//             });
//             formRef.current.reset();
//         } catch (err) {
//             setError('Error sending email. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <>
//             <Modal
//                 {...props}
//                 size="md"
//                 aria-labelledby="apply-modal-title"
//                 centered
//                 scrollable
//                 className="common-main-modal"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title id="apply-modal-title">
//                         <h4 className="modal-headdd">
//                             {props.generic ? 'Submit Profile' : 'Apply'}
//                         </h4>
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form ref={formRef} onSubmit={handleSubmit}>
//                         <Form.Group className="mb-3" controlId="fullName">
//                             <Form.Label>Full Name *</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 required
//                                 name="fullName"
//                                 value={formData.fullName}
//                                 onChange={handleChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="contact">
//                             <Form.Label>Contact *</Form.Label>
//                             <Form.Control
//                                 type="tel"
//                                 required
//                                 name="contact"
//                                 value={formData.contact}
//                                 onChange={handleChange}
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3" controlId="email">
//                             <Form.Label>Email *</Form.Label>
//                             <Form.Control
//                                 type="email"
//                                 required
//                                 name="email"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                             />
//                         </Form.Group>
//                         {!props.generic && (
//                             <Form.Group className="mb-3" controlId="position">
//                                 <Form.Label>Position *</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={props.jobTitle}
//                                     disabled
//                                     name="position"
//                                 />
//                             </Form.Group>
//                         )}
//                         <Form.Group className="mb-3" controlId="resume">
//                             <Form.Label>Resume *</Form.Label>
//                             <Form.Control
//                                 type="file"
//                                 accept=".pdf,.doc,.docx"
//                                 required
//                                 name="resume"
//                                 onChange={handleFileChange}
//                             />
//                         </Form.Group>
//                         <Modal.Footer>
//                             <div className="job-card-main">
//                                 <button
//                                     className="know-more-btn-job"
//                                     type="submit"
//                                     aria-label={props.generic ? 'Submit Resume' : 'Submit Application'}
//                                     disabled={loading}
//                                 >
//                                     {loading ? 'Submitting...' : props.generic ? 'Submit Resume' : 'Submit Application'}
//                                 </button>
//                                 <button
//                                     className="apply-btn"
//                                     onClick={props.onHide}
//                                     aria-label="Cancel Application"
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </Modal.Footer>
//                     </Form>

                   
//                     {/* {error && <div className="alert alert-danger">{error}</div>}
//                     {success && <div className="alert alert-success">Application submitted successfully!</div>} */}
//                 </Modal.Body>
//             </Modal>
//         </>
//     );
// };

// export default ApplyModal;

import React, { useState, useRef } from 'react';
import { Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';  

const backendUrl = 'http://127.0.0.1:3004/send-email';

const ApplyModal = (props) => {
    const formRef = useRef(null);
    const [formData, setFormData] = useState({
        fullName: '',
        contact: '',
        email: '',
        resume: null,
        role: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle resume file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData((prevData) => ({
                    ...prevData,
                    resume: reader.result,
                }));
            };
            reader.readAsDataURL(file); // Convert file to base64
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await axios.post(backendUrl, {
                ...formData,
                resume: formData.resume,
                role: props.jobTitle || formData.role,
            });

            setSuccess(true);
            toast.success('Application submitted successfully!'); // Success toast
            setFormData({
                fullName: '',
                contact: '',
                email: '',
                resume: null,
                role: '',
            });
            formRef.current.reset();
            setLoading(false);
        } catch (err) {
            setError('Error sending email. Please try again.');
            toast.error('We apologize, but your application could not be submitted. Please send an email for your application at hr@redberyltech.com');
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="apply-modal-title"
                centered
                scrollable
                className="common-main-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="apply-modal-title">
                        <h4 className="modal-headdd">
                            {props.generic ? 'Submit Profile' : 'Apply'}
                        </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="fullName">
                            <Form.Label>Full Name *</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="contact">
                            <Form.Label>Contact *</Form.Label>
                            <Form.Control
                                type="tel"
                                required
                                name="contact"
                                value={formData.contact}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control
                                type="email"
                                required
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        {props.generic ? (
                            <Form.Group className="mb-3" controlId="category">
                                <Form.Label>Job Category *</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="category"
                                    required
                                    // value={formData.category}
                                    // onChange={handleChange}
                                >
                                    <option>Java Developer</option>
                                    <option>Python Developer</option>
                                    <option>AWS Engineer</option>
                                    <option>Cloud Engineer</option>
                                    <option>Frontend Developer</option>
                                    <option>Database Admin</option>
                                    <option>AI Developer</option>
                                    <option>Bussiness Analyst</option>
                                    <option>HR Admin</option>
                                    <option>QA Engineer</option>
                                </Form.Control>
                            </Form.Group>
                        ) : (
                            <Form.Group className="mb-3" controlId="position">
                                <Form.Label>Position *</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={props.jobTitle}
                                    disabled
                                    name="position"
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3" controlId="resume">
                            <Form.Label>Resume *</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".pdf,.doc,.docx"
                                required
                                name="resume"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <div className="job-card-main">
                                <button
                                    className="know-more-btn-job"
                                    type="submit"
                                    aria-label={props.generic ? 'Submit Resume' : 'Submit Application'}
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : props.generic ? 'Submit Resume' : 'Submit Application'}
                                </button>
                                <button
                                    className="apply-btn"
                                    onClick={props.onHide}
                                    aria-label="Cancel Application"
                                >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Toaster to display notifications */}
            <Toaster
                position="top-center"
                reverseOrder={false} 
                toastOptions={{
                    duration: 2000, 
                    style: {
                        background: '#043199',
                        color: '#E3075E',
                        fontFamily:"Poppins",
                        fontWeight:700,
                        borderRadius: '8px', 
                        padding: '10px 20px', 
                },
                success: {
                    duration: 3000, 
                    style: {
                        background: '#E3075E', color: '#fff', },
                },
                error: {
                    duration: 3000, 
                    style: {
                        background: '#043199', 
                        color: '#fff', 
                    },
                },
                loading: {
                    duration: 2000, 
                    style: {
                        background: '#FFA500', 
                        color: '#fff', 
                    },
                },
                }}
            />
        </>
    );
};

export default ApplyModal;