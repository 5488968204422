import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faLocationDot, faUserGear } from "@fortawesome/free-solid-svg-icons";
import KnowMoreModalRecruiter from "./KnowMoreModalRecruiter";  // Import modal
import "../../job-seeker/job-card/JobCard.css";
import GetProfileModal from "./GetProfileModal";

const RecruiterCard = ({ data, onApply = () => {} }) => {
    const [showModal, setShowModal] = useState(false);

    // Handle modal show/hide for KnowMoreModal
    const handleModalShow = () => setShowModal(true);
    const handleModalHide = () => setShowModal(false);

    // Handle modal show/hide for GetProfileModal
    const [showGetProfileModal, setShowGetProfileModal] = useState(false);

    const handleGetProfileModalShow = () => setShowGetProfileModal(true);
    const handleGetProfileModalHide = () => setShowGetProfileModal(false);

    return (
        <section className="job-card-main">
            <div className="main-card-white">
                <div className="d-md-flex d-0 justify-content-between">
                    <div>
                        <h4 className="blue-text">{data.jobTitle}</h4>
                        <div className="d-flex">
                            <p className="me-4 normal-text-card">
                                <FontAwesomeIcon icon={faCalendarDays} className="me-2" />
                                {data.experience} Years
                            </p>
                            <p className="me-4 normal-text-card">
                                <FontAwesomeIcon icon={faUserGear} className="me-2" />
                                {data.highestQuali}
                                {console.log(data)}
                            </p>
                            <p className="normal-text-card">
                                <FontAwesomeIcon icon={faLocationDot} />
                                {data.location}
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src={process.env.PUBLIC_URL + data.imageSrc}
                            alt="User"
                            className="user-img-card"
                        />
                    </div>
                </div>
                <div>
                    <p className="job-desc">{data.skills.join(", ")}</p>
                    <div>
                        <button className="know-more-btn-job" onClick={handleModalShow}>
                            Know More
                        </button>
                        <button className="apply-btn" onClick={handleGetProfileModalShow}>
                            Get Profile
                        </button>
                    </div>
                </div>
            </div>

            {/* Modal Triggered Here */}
            <KnowMoreModalRecruiter
                show={showModal}
                onHide={handleModalHide}
                data={data} // Pass the recruiter data to the modal
            />

            {/* Modal Triggered Here */}
            <GetProfileModal
                show={showGetProfileModal}
                onHide={handleGetProfileModalHide}
                data={data} // Pass the recruiter data to the modal
            />
        </section>
    );
};

export default RecruiterCard;
